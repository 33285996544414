import React from 'react';
import phone from '../../../assets/phone.svg'; 
import google from '../../../assets/google.svg';
import play from '../../../assets/apple.svg';

const Product2Mobile = () => {
  return (
    <div style={mobileStyles.container}>
      <div style={mobileStyles.top}>
        <div style={mobileStyles.middle}>
          <img src={phone} alt="Phone" style={mobileStyles.image} />
        </div>
      </div>
      <div style={mobileStyles.bottom}>
        <div style={mobileStyles.textContainer}>
          <h3 style={mobileStyles.heading}>BCS App</h3>
          <p style={mobileStyles.text}>
          Scan and digitize business cards effortlessly with the Habsy Mobile App, making contact management simple and secure.
          </p>
          <div style={mobileStyles.iconContainer}>
            <img src={google} alt='google' style={{width: "150px",paddingLeft: "50px"}}/>
            <img src={play} alt="Visit"  style={{width: "100px"}}/>
          </div>
        </div>
      </div>
    </div>
  );
};

const mobileStyles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: 'auto',
      backgroundColor: '#ffffff',
      padding: '0px',
    },
    top: {
      width: '100%',
      maxWidth: '440px',
      height: '240px',
      backgroundColor: '#034C9B',
      position: 'relative',
      borderTopRightRadius: '15px',
      borderTopLeftRadius: '20px',
      zIndex: 1,
    },
    middle: {
      width: '100%',
      maxWidth: '440px',
      height: '190px',
      backgroundColor: '#7798BB',
      borderTopLeftRadius: '200px',
      borderTopRightRadius: '200px',
      position: 'absolute',
      top: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'visible',
      zIndex: 2,
    },
    bottom: {
      width: '100%',
      maxWidth: '300px',
      height: '190px',
      backgroundColor: '#0D66C6',
      borderBottomLeftRadius: '20px',
      borderBottomRightRadius: '20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      zIndex: 0,
      padding: '10px',
    },
    image: {
      width: '80%',
      maxWidth: '240px',
      height: 'auto',
      position: 'absolute',
      top: '-5px',
      zIndex: 3,
    },
    textContainer: {
      width: '100%',
      textAlign: 'center', 
      paddingLeft: '0px', 
      paddingRight: '0px', 
      paddingTop: '10px',
      position: 'relative',
    },
    heading: {
      color: '#ffffff',
      margin: '10px 0 3px 0',
      fontSize: '20px',
    },
    text: {
      fontSize: '16px',
      color: '#ffffff',
      margin: '0 0 10px 0', 
      lineHeight: '1.4',
    },
    iconContainer: {
      display: 'flex',
      gap: '10px', 
    },
  };

export default Product2Mobile;
