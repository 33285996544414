import React from 'react';
import Product1 from './Product1/Product1';
import Product2 from './Product2/Product2';

const ProductMobile = () => {
  return (
    <>
      <h1 style={styles.heading}>Explore Our Products</h1>
      <div style={styles.container}>
        <div style={styles.product1}>
          <Product1 />
        </div>
        <div style={styles.product2}>
          <Product2 />
        </div>
      </div>
    </>
  );
}

const styles = {
  heading: {
    textAlign: 'center', 
    marginBottom: '20px', 
  },
  container: {
    display: 'flex',
    flexDirection: 'column', 
    padding: '0 20px', 
    gap: '20px', 
  },
  product1: {
    flex: 1, 
    display: 'flex',
    justifyContent: 'center', 
  },
  product2: {
    flex: 1, 
    display: 'flex',
    justifyContent: 'center', 
  },
};

export default ProductMobile;
