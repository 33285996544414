import React from 'react';
import hero from '../../assets/dashboard.svg';
import logo from '../../assets/logo.svg';
import Services from '../Solutions/Solutions';
import Features from '../Features/Features';
import Footer from '../Footer';
import Product from '../Product/Product';
import EnquiryForm from '../../components/EnquiryForm/EnquiryForm';

const DesktopApp = () => {
  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.logoContainer}>
          <div style={styles.logo}>
            <img src={logo} alt='logo' style={styles.logoImage} />
          </div>
        </div>
        <div style={styles.nav}>
          <ul style={styles.navList}>
            <li style={styles.navItem}><a href="#home" style={{textDecoration: 'none',color: "black"}}>Home</a></li>
            <li style={styles.navItem}><a href="#solutions" style={{textDecoration: 'none',color: "black"}}>Solutions</a></li>
            <li style={styles.navItem}><a href="#features" style={{textDecoration: 'none',color: "black"}}>Features</a></li>
            <li style={styles.navItem}><a href="#footer" style={{textDecoration: 'none',color: "black"}}>Contact Us</a></li>
          </ul>
        </div>
      </div>
      <div style={styles.hero} id="home">
        <div style={styles.imageContainer}>
          <img src={hero} alt="Hero" style={styles.heroImage} />
        </div>
        <div style={styles.textContainer}>
          <h1 style={styles.heroHeading}>Revolutionizing Business Card Management</h1>
          <p style={styles.heroParagraph}>
            Say goodbye to manual data entry with Habsy. Our cutting-edge AI converts your physical business cards into a secure digital database, accessible anytime via our sleek app and dashboard. Perfect for businesses of all sizes, Habsy offers a streamlined, scalable solution for managing your contacts efficiently and securely.
          </p>
        </div>
      </div>
      <div id='solutions' style={styles.sectionPadding}>
        <Services />
      </div>
      <div id='features' style={styles.sectionPadding}>
        <Features />
      </div>
        <Product />
      <div>
        <EnquiryForm />
      </div>
      <div id='footer' style={styles.sectionPadding}>
        <Footer />
      </div>
    </div>
  );
};


const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    margin: 0,
    padding: 0,
  },
  header: {
    width: '100%',
    display: 'flex',
    backgroundColor: 'white',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    position: 'fixed',
    top: 0,
    left: 0,
    padding: 0,
    boxSizing: 'border-box',
    alignItems: 'center',
    zIndex: 1000,
  },
  logoContainer: {
    background: 'white',
    overflow: 'hidden',
    position: 'absolute',
    left: '0',
  },
  logo: {
    display: 'flex',
  },
  logoImage: {
    maxHeight: '50px',
    marginLeft: '50px'
  },
  nav: {
    display: 'flex',
    backgroundColor: 'white',
    marginLeft: 'auto',
    borderBottomLeftRadius: '50px',
    borderBottomRightRadius: '50px',
    height: '80px',
    alignItems: 'center',
    position: 'relative',
    flexWrap: 'wrap',
  },
  navList: {
    display: 'flex',
    listStyle: 'none',
    margin: 0,
    padding: 0,
    flexWrap: 'wrap',
  },
  navItem: {
    margin: '0 30px',
    color: 'black',
    cursor: 'pointer',
    fontSize: '17px',
    textDecoration: 'none'
  },
  hero: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '100px 20px',
    background: 'linear-gradient(to right, #00B4DB, #0083B0)',
    color: '#ffffff',
    flexDirection: 'row-reverse',
    boxSizing: 'border-box',
    marginTop: '80px',
  },
  textContainer: {
    flex: '1',
    fontSize: "20px",
    lineHeight: '2',
    padding: '0 20px',
  },
  imageContainer: {
    flex: '1',
    display: 'flex',
    justifyContent: 'center',
  },
  heroHeading: {
    fontSize: "40px",
  },
  heroImage: {
    width: '100%',
    maxWidth: '700px',
    borderRadius: '10px',
  },
  heroParagraph: {
    marginTop: '20px',
    textAlign: 'justify',
    paddingRight: '100px'
  },
  sectionPadding: {
    paddingTop: '80px', 
  },
  '@media (max-width: 1200px)': {
    logoContainer: {
      background: 'white',
    },
    nav: {
      padding: '0',
    },
    navItem: {
      margin: '0 20px',
    },
    heroHeading: {
      fontSize: '35px',
    },
    textContainer: {
      padding: '0 10px',
    },
    sectionPadding: {
      paddingTop: '80px',
    },
  },
  '@media (max-width: 992px)': {
    logoContainer: {
      background: 'white',
    },
    nav: {
      padding: '0',
      height: 'auto',
      borderRadius: '20px',
    },
    navItem: {
      margin: '0 15px',
    },
    heroHeading: {
      fontSize: '30px',
    },
    textContainer: {
      padding: '0 5px',
    },
    sectionPadding: {
      paddingTop: '80px',
    },
  },
  '@media (max-width: 768px)': {
    logoContainer: {
      background: 'white',
    },
    nav: {
      padding: '0',
      flexDirection: 'column',
      borderRadius: '10px',
    },
    navList: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    navItem: {
      margin: '10px 0',
    },
    heroHeading: {
      fontSize: '25px',
    },
    textContainer: {
      padding: '0 10px',
    },
    sectionPadding: {
      paddingTop: '80px',
    },
  },
};

export default DesktopApp;

