import React from 'react';
import phone from '../../../assets/phone.svg'; 
import play from '../../../assets/apple.svg';
import google from '../../../assets/google.svg'

const Product2Desktop = () => {
  return (
    <div style={desktopStyles.container}>
      <div style={desktopStyles.top}>
        <div style={desktopStyles.middle}>
          <img src={phone} alt="Phone" style={desktopStyles.image} />
        </div>
      </div>
      <div style={desktopStyles.bottom}>
        <div style={desktopStyles.textContainer}>
          <h3 style={desktopStyles.heading}>BCS App</h3>
          <p style={desktopStyles.text}>
          Scan and digitize business cards effortlessly with the Habsy Mobile App, making contact management simple and secure.
          </p>
          <div style={desktopStyles.iconContainer}>
            <img src={google} alt='Google Play' style={desktopStyles.icon} />
            <img src={play} alt="Apple Store" style={desktopStyles.icon} />
          </div>
        </div>
      </div>
    </div>
  );
};

const desktopStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh',
    backgroundColor: '#ffffff',
    padding: '20px',
  },
  top: {
    width: '100%',
    maxWidth: '440px',
    height: '250px',
    backgroundColor: '#034C9B',
    position: 'relative',
    borderTopRightRadius: '15px',
    borderTopLeftRadius: '20px',
    zIndex: 1,
  },
  middle: {
    width: '100%',
    maxWidth: '440px',
    height: '220px',
    backgroundColor: '#7798BB',
    borderTopLeftRadius: '300px',
    borderTopRightRadius: '300px',
    position: 'absolute',
    top: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'visible',
    zIndex: 2,
  },
  bottom: {
    width: '100%',
    maxWidth: '400px',
    height: '250px',
    backgroundColor: '#0D66C6',
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    zIndex: 0,
    padding: '20px',
  },
  image: {
    width: '80%',
    maxWidth: '350px',
    height: 'auto',
    position: 'absolute',
    top: '-20px',
    zIndex: 3,
  },
  textContainer: {
    width: '100%',
    textAlign: 'left',
    paddingLeft: '20px',
    position: 'relative',
  },
  heading: {
    color: '#ffffff',
    margin: '0 0 5px 0',
    fontSize: '24px',
    paddingTop: "60px"
  },
  text: {
    fontSize: '20px',
    color: '#ffffff',
    margin: '0 0 -10px 0',
    lineHeight: '1.4',
    paddingBottom: '3px'
  },
  iconContainer: {
    display: 'flex',
    gap: '10px',
    justifyContent: 'center' 
  },
  '@media (max-width: 1200px)': {
    middle: {
      top: '40px',
    },
    image: {
      width: '70%',
    },
  },
  '@media (max-width: 700px)': {
    middle: {
      top: '20px',
    },
    image: {
      width: '60%',
    },
    textContainer: {
      paddingLeft: '10px',
    },
    heading: {
      fontSize: '20px',
    },
    text: {
      fontSize: '16px',
    },
  },
};

export default Product2Desktop;
