import React, { useState, useEffect } from 'react';
import FeaturesDesktop from './FeaturesDesktop';
import FeaturesMobile from './FeaturesMobile';

const Features = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile ? <FeaturesMobile /> : <FeaturesDesktop />;
};

export default Features;
