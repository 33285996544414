import React from 'react';
import solution1 from '../../assets/solutions1.svg';
import solution2 from '../../assets/solutions2.svg';
import solution3 from '../../assets/solutions3.svg';

const ServicesMobile = () => {
  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Why Our Solution is a Game-Changer</h2>
      <div style={styles.serviceItem}>
        <div style={styles.serviceImage}>
          <img src={solution1} alt="Service 1" style={styles.image} />
        </div>
        <div style={styles.serviceContent}>
          <p style={styles.text}>
            We specialize in transforming your physical business cards into organized, digital contacts that are securely stored and easily accessible. Our platform allows you to efficiently categorize and retrieve your contacts with advanced search and filtering options, ensuring you always have the right information at your fingertips.
          </p>
        </div>
      </div>
      <div style={styles.serviceItem}>
        <div style={styles.serviceImage}>
          <img src={solution2} alt="Service 2" style={styles.image} />
        </div>
        <div style={styles.serviceContent}>
          <p style={styles.text}>
          Our platform captures and retains every business connection you make, ensuring they’re always at your fingertips, regardless of where you first met or how long ago. With our service, your contacts are always accessible, whether you're on the go with your smartphone or at your desk with a computer. This multi-device accessibility ensures you're always connected to your network.
          </p>
        </div>
      </div>
      <div style={styles.serviceItem}>
        <div style={styles.serviceImage}>
          <img src={solution3} alt="Service 3" style={styles.image} />
        </div>
        <div style={styles.serviceContent}>
          <p style={styles.text}>
          Quickly find key contacts using advanced search and filtering options, helping you uncover hidden opportunities and maximize your existing network. Save time and effort by quickly finding forgotten contacts and making introductions. Our platform streamlines these processes, saving your organization valuable time and resources.
          </p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '1rem', 
    backgroundColor: '#f8f9fa',
  },
  title: {
    textAlign: 'center',
    fontSize: '1.2rem',
    marginBottom: '1rem', 
    fontWeight: 'bold'
  },
  serviceItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '18px',
    fontWeight: '500',
    marginBottom: '1rem', 
  },
  serviceContent: {
    textAlign: 'center',
    padding: '1rem',
    fontSize: '16px'
  },
  serviceImage: {
    textAlign: 'center',
    padding: '1rem',
  },
  image: {
    maxWidth: '100%',
    height: 'auto',
    borderRadius: '50%',
  },
  text: {
    marginBottom: '1rem',
    textAlign: 'justify',
  },
  '@media (max-width: 768px)': {
    container: {
      padding: '0.5rem', 
    },
    title: {
      fontSize: '1.25rem', 
      marginTop: '0.25rem', 
    },
    serviceItem: {
      fontSize: '16px',
    },
  },
};

export default ServicesMobile;
