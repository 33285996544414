import React from 'react';
import { useMediaQuery } from 'react-responsive';
import DesktopApp from './HomeDesktop';
import MobileApp from './HomeMobile';

const Home = () => {
  const isDesktop = useMediaQuery({ minWidth: 724 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <div>
      {isDesktop && <DesktopApp />}
      {isMobile && <MobileApp />}
    </div>
  );
};

export default Home;
