import React from 'react';

const FeaturesMobile = () => {
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#00b0e5',
    padding: '15px',
    color: 'white',
    width: '100vw',
    height: 'auto',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    boxSizing: 'border-box',
    marginBottom: "20px"
  };

  const headingStyle = {
    fontSize: '18px',
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#ffffff',
  };

  const featureTextStyle = {
    color: "white",
    fontSize: '16px',
    lineHeight: '1.8',
    padding: '0 10px',
    textAlign: 'justify',
  };

  return (
    <div style={containerStyle}>
        <div style={headingStyle}>
          <h1>Features</h1>
        </div>
      <div style={featureTextStyle}>
        <p>
          Habsy revolutionizes how you manage your business cards by converting
          them into digital formats with our automated scanning and extraction
          technology, saving you from manual data entry. We ensure the highest
          level of security with encrypted storage and secure access, keeping
          your contacts safe. Our user-friendly design allows for easy
          navigation and quick access, while our scalable solution grows with
          your business, adapting to your expanding network.
        </p>
      </div>
    </div>
  );
};

export default FeaturesMobile;
