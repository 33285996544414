import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home/Home';
import PrivacyPolicy from './privacyPolicy';
import Footer from './components/Footer'; 
import EnquiryForm from './components/EnquiryForm/EnquiryForm';

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path='/privacypolicy' element={<PrivacyPolicy />} />
          <Route path='/contact' element={<Footer />} />
          <Route path='/enquire' element={<EnquiryForm />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
