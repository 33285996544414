import React from 'react';
import { useMediaQuery } from '@mui/material';
import EnquiryFormMobile from './EnquiryFormMobile';
import EnquiryFormDesktop from './EnquiryFormDesktop';

const EnquiryForm = () => {
  const isMobile = useMediaQuery('(max-width: 767px)');

  return isMobile ? <EnquiryFormMobile /> : <EnquiryFormDesktop />;
};

export default EnquiryForm;
