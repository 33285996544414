import React from 'react';
import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin, FaPhone, FaEnvelope, FaMapMarkerAlt, FaWhatsapp } from 'react-icons/fa';
import logo from '../assets/logo.svg';

function Footer() {
  return (
    <footer className="footer">
      <style>
        {`
          .footer {
            background-color: #6d6d6d;
            color: #fff;
            padding: 2rem 1rem;
          }

          .footer-content {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
          }

          .footer-logo {
            flex: 1;
            max-width: 400px;
            margin-bottom: 1rem;
            margin-left: 1rem;
          }

          .footer-logo h1 {
            color: #00b4d8;
          }

          .footer-logo p {
            margin: 2rem 0;
          }

          .footer-social {
            display: flex;
            gap: 1rem;
            margin-bottom: 1rem;
          }

          .footer-social svg {
            color: #fff;
            font-size: 1.5rem;
            margin-right: 1rem;
          }

          .footer-links {
            flex: 1;
            max-width: 200px;
            margin-bottom: 1rem;
            text-align: center; 
          }

          .footer-links h2 {
            margin-bottom: 1rem;
          }

          .footer-links ul {
            list-style: none;
            padding: 0;
          }

          .footer-links li {
            margin-bottom: 0.5rem;
          }

          .footer-contact {
            flex: 1;
            max-width: 200px;
            margin-bottom: 1rem;
            margin-left: -2rem; 
          }

          .footer-contact h2 {
            margin-bottom: 1rem;
          }

          .footer-contact ul {
            list-style: none;
            padding: 0;
          }

          .footer-contact li {
            margin-bottom: 0.5rem;
            display: flex;
            align-items: center;
            gap: 0.5rem;
          }

          .footer-contact svg {
            font-size: 1.2rem;
          }

          .footer-bottom {
            text-align: center;
            margin-top: 2rem;
            border-top: 1px solid #fff;
            padding-top: 1rem;
            font-size: 0.875rem;
          }

          @media (max-width: 768px) {
            .footer-content {
              flex-direction: column;
              align-items: center;
              text-align: center;
            }

            .footer-logo,
            .footer-links,
            .footer-contact {
              max-width: 100%;
            }

            .footer-social {
              justify-content: center;
            }

            .footer-contact {
              margin-left: 0; 
            }
          }
            .rotate-icon {
              transform: rotate(95deg);
            }
        `}
      </style>
      <div className="footer-content">
        <div className="footer-logo">
          <img src={logo} alt='logo' />
          <p>
            Turning innovative ideas into successful products and thriving
            companies through incubation.
          </p>
          <div className="footer-social">
            <FaFacebook />
            <FaInstagram />
            <FaTwitter />
            <a href="https://www.linkedin.com/company/habsy-technologies-private-limited/" target="_blank" rel="noopener noreferrer" style={{ color: '#0077b5', textDecoration: 'none' }}>
              <FaLinkedin />
            </a>
          </div>
        </div>
        <div className="footer-links">
          <h2>Links</h2>
          <ul>
            <li>About Us</li>
            <li>Service</li>
            <li>Careers</li>
            <li>
              <a href="/privacypolicy" style={{ color: '#fff', textDecoration: 'none' }}>
                Terms and Privacy
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-contact">
          <h2>Contact Us</h2>
          <ul>
            <li><FaPhone className="rotate-icon" /> +91 9368148390</li>
            <li><FaEnvelope /> contact@habsy.in</li>
            <li><FaMapMarkerAlt /> Chennai</li>
            <li><FaWhatsapp /> +91 9368148390</li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>@ 2024 Copyright by Habsy Technologies Private Limited.
        All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
