import React, { useState, useEffect } from 'react';
import ProductDesktop from './ProductDesktop';
import ProductMobile from './ProductMobile';

const App = () => {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= 1200 
  );

  useEffect(() => {
    const handleResize = () => 
      setIsMobile(window.innerWidth <= 1200); 

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {isMobile ? <ProductMobile /> : <ProductDesktop />}
    </>
  );
};

export default App;
