import React, { useState, useEffect } from 'react';
import Product2Desktop from './Product2Desktop';
import Product2Mobile from './Product2Mobile';

const App = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1500);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 1500);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {isMobile ? <Product2Mobile /> : <Product2Desktop />}
    </>
  );
};

export default App;
