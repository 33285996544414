import React, { useState, useEffect } from 'react';
import SolutionDesktop from './SolutionsDesktop';
import SolutionMobile from './SolutionsMobile';

const Services = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile ? <SolutionMobile /> : <SolutionDesktop />;
};

export default Services;
