import React from 'react';

const FeaturesDesktop = () => {
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#00b0e5',
    padding: '30px',
    color: 'white',
    width: '100vw',
    height: '60vh',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    boxSizing: 'border-box',
  };

  const headingStyle = {
    fontSize: '24px',
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#ffffff',
    marginBottom: '-10px',
  };

  const featureTextStyle = {
    color: "white",
    fontSize: '20px',
    lineHeight: '2.4',
    flex: 1,
    maxWidth: '1200px',
    textAlign: 'justify',
    paddingTop: '20px'
  };

  return (
    <div style={containerStyle}>
        <div style={headingStyle}>
          <h1>Features</h1>
        </div>
      <div style={featureTextStyle}>
        <p>
        Habsy revolutionizes business card management by leveraging cutting-edge AI/ML solutions to seamlessly convert physical cards into digital formats. Our advanced automated scanning and extraction technology eliminates the need for manual data entry, ensuring a smooth and efficient process. With a centralized digital database, you can access your contacts effortlessly through a user-friendly dashboard and mobile app. We prioritize the security of your information with encrypted storage and secure access protocols. Designed to grow with your business, Habsy offers a scalable solution that adapts to your expanding network, making contact management easier and more secure than ever before.
        </p>
      </div>
    </div>
  );
};

export default FeaturesDesktop;
