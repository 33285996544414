import React, { useRef } from 'react';
import laptop from '../../../assets/laptop.svg';

const Product1Desktop = () => {
  const enquiryFormRef = useRef(null);
  const bottomElementRef = useRef(null); 

  const scrollToEnquiryForm = () => {
    if (enquiryFormRef.current) {
      enquiryFormRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div style={desktopStyles.container}>
      <div style={desktopStyles.top}>
        <div style={desktopStyles.middle}>
          <img src={laptop} alt="laptop" style={desktopStyles.image} />
        </div>
      </div>
      <div style={desktopStyles.bottom} ref={bottomElementRef}> 
        <div style={desktopStyles.textContainer}>
          <h3 style={desktopStyles.heading}>BCS Admin Web</h3>
          <p style={desktopStyles.text}>
            Explore and manage your digital business contacts with ease using Habsy's intuitive dashboard,
          </p>
          <button style={desktopStyles.button} onClick={scrollToEnquiryForm}>
            Enquire now
          </button>
        </div>
      </div>
      <div ref={enquiryFormRef}></div> 
    </div>
  );
};

const desktopStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh',
    backgroundColor: '#ffffff',
    padding: '20px',
  },
  top: {
    width: '100%',
    maxWidth: '440px',
    height: '250px',
    backgroundColor: '#285361',
    position: 'relative',
    borderTopRightRadius: '15px',
    borderTopLeftRadius: '20px',
    zIndex: 1,
  },
  middle: {
    width: '100%',
    maxWidth: '440px',
    height: '220px',
    backgroundColor: '#487E90',
    borderTopLeftRadius: '300px',
    borderTopRightRadius: '300px',
    position: 'absolute',
    top: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'visible',
    zIndex: 2,
  },
  bottom: {
    width: '100%',
    maxWidth: '400px',
    height: '250px',
    backgroundColor: '#0090BF',
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    zIndex: 0,
    padding: '20px',
  },
  image: {
    width: '80%',
    maxWidth: '350px',
    height: 'auto',
    position: 'absolute',
    top: '-20px',
    zIndex: 3,
  },
  textContainer: {
    width: '100%',
    textAlign: 'left',
    paddingLeft: '20px',
    position: 'relative',
  },
  heading: {
    color: '#ffffff',
    margin: '0 0 5px 0',
    fontSize: '24px',
  },
  text: {
    fontSize: '20px',
    color: '#ffffff',
    margin: '0 0 20px 0',
    lineHeight: '1.5',
    paddingBottom: '10px'
  },
  button: {
    position: 'absolute',
    bottom: '-20px',
    border: 'none',
    borderRadius: '10px',
    backgroundColor: 'white',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: '50%',
    transform: 'translateX(-50%)',
    paddingLeft: '45px',
    paddingRight: '45px',
    color: '#007BFF',
    height: '40px',
  },
  '@media (max-width: 1200px)': {
    middle: {
      top: '40px',
    },
    image: {
      width: '70%',
    },
  },
  '@media (max-width: 700px)': {
    middle: {
      top: '20px',
    },
    image: {
      width: '60%',
    },
    textContainer: {
      paddingLeft: '10px',
    },
    heading: {
      fontSize: '20px',
    },
    text: {
      fontSize: '16px',
    },
  },
};

export default Product1Desktop;
