import React, { useRef } from 'react';
import laptop from '../../../assets/laptop.svg'; 

const MobileView = () => {
  const bottomElementRef = useRef(null); 

  const scrollToBottom = () => {
    if (bottomElementRef.current) {
      bottomElementRef.current.scrollIntoView({ behavior: 'smooth' });
      window.scrollBy(0, 600); 
    }
  };

  return (
    <div style={mobileStyles.container}>
      <div style={mobileStyles.top}>
        <div style={mobileStyles.middle}>
          <img src={laptop} alt="Laptop" style={mobileStyles.image} />
        </div>
      </div>
      <div style={mobileStyles.bottom}>
        <div style={mobileStyles.textContainer}>
          <h3 style={mobileStyles.heading}>BCS Admin Web</h3>
          <p style={mobileStyles.text}>
            Explore and manage your digital business contacts with ease using Habsy's intuitive dashboard,
          </p>
          <button style={mobileStyles.button} onClick={scrollToBottom}>
            Enquire now
          </button>
        </div>
      </div>
      <div ref={bottomElementRef} style={mobileStyles.enquiryFormContainer}>
        {/* Content of your enquiry form */}
      </div>
    </div>
  );
};

const mobileStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'auto',
    backgroundColor: '#ffffff',
    padding: '0px',
  },
  top: {
    width: '100%',
    maxWidth: '440px',
    height: '220px',
    backgroundColor: '#285361',
    position: 'relative',
    borderTopRightRadius: '15px',
    borderTopLeftRadius: '20px',
    zIndex: 1,
  },
  middle: {
    width: '100%',
    maxWidth: '440px',
    height: '190px',
    backgroundColor: '#487E90',
    borderTopLeftRadius: '200px',
    borderTopRightRadius: '200px',
    position: 'absolute',
    top: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'visible',
    zIndex: 2,
  },
  bottom: {
    width: '100%',
    maxWidth: '300px',
    height: '190px',
    backgroundColor: '#0090BF',
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    zIndex: 0,
    padding: '10px',
  },
  image: {
    width: '80%',
    maxWidth: '240px',
    height: 'auto',
    position: 'absolute',
    top: '-16px',
    zIndex: 3,
  },
  textContainer: {
    width: '100%',
    textAlign: 'center', 
    paddingLeft: '0px', 
    paddingRight: '0px',
    paddingTop: '10px', 
    position: 'relative',
  },
  heading: {
    color: '#ffffff',
    margin: '10px 0 3px 0',
    fontSize: '20px',
  },
  text: {
    fontSize: '16px',
    color: '#ffffff',
    margin: '0 0 10px 0', 
    lineHeight: '1.4',
  },
  button: {
    border: 'none',
    borderRadius: '10px',
    padding: '5px 10px',  
    fontSize: '16px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto', 
    backgroundColor: "white",
    paddingLeft: '45px',
    paddingRight: '45px',
    color: '#007BFF',
  },
  enquiryFormContainer: {
    height: '1px', 
  },
};

export default MobileView;
