import React, { useState, useEffect } from 'react';
import DesktopView from './Product1Desktop';
import MobileView from './Product1Mobile';

const App = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1500);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 1500);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {isMobile ? <MobileView /> : <DesktopView />}
    </>
  );
};

export default App;
