import React, { useState } from 'react';
import hero from '../../assets/dashboard.svg'; 
import logo from '../../assets/logo.svg';
import Services from '../Solutions/Solutions';
import Features from '../Features/Features';
import Footer from '../Footer';
import Product from '../Product/Product';
import EnquiryForm from '../../components/EnquiryForm/EnquiryForm';

const MobileApp = () => {
  const [navOpen, setNavOpen] = useState(false);

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  const closeNav = () => {
    setNavOpen(false);
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.logoContainer}>
          <div style={styles.logo}>
            <img src={logo} alt='logo' />
          </div>
          <div style={styles.hamburger} onClick={toggleNav}>
            <div style={styles.bar}></div>
            <div style={styles.bar}></div>
            <div style={styles.bar}></div>
          </div>
        </div>
        {navOpen && (
          <div style={styles.nav}>
            <ul style={styles.navList}>
              <li style={styles.navItem} onClick={closeNav}><a href="#home" style={{textDecoration: 'none',color: "black"}}>Home</a></li>
              <li style={styles.navItem} onClick={closeNav}><a href="#solutions" style={{textDecoration: 'none',color: "black"}}>Solutions</a></li>
              <li style={styles.navItem} onClick={closeNav}><a href="#features" style={{textDecoration: 'none',color: "black"}}>Features</a></li>
              <li style={styles.navItem} onClick={closeNav}><a href="#footer" style={{textDecoration: 'none',color: "black"}}>Contact Us</a></li>
            </ul>
          </div>
        )}
      </div>
      <div style={styles.hero} id='home'>
        <div style={styles.textContainer}>
          <h1 style={styles.h1}>
            Revolutionizing Business Card Management
          </h1>
          <p style={styles.heroParagraph}>
            Say goodbye to manual data entry with Habsy. Our cutting-edge AI converts your physical business cards into a secure digital database, accessible anytime via our sleek app and dashboard. Perfect for businesses of all sizes, Habsy offers a streamlined, scalable solution for managing your contacts efficiently and securely.
          </p>
        </div>
        <div style={styles.imageContainer}>
          <img src={hero} alt="Hero" style={styles.heroImage} />
        </div>
      </div>
      <div id='solutions' style={styles.sectionPadding}>
        <Services />
      </div>
      <div id='features' style={styles.sectionPadding}>
        <Features />
      </div>
      <div>
        <Product />
      </div>
      <div>
        <EnquiryForm />
      </div>
      <div id='footer' style={styles.sectionPadding}>
        <Footer />
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '40vh',
    margin: 0,
    padding: 0,
  },
  header: {
    width: '100%',
    display: 'flex',
    position: 'fixed',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    zIndex: 1000
  },
  logoContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '13px 10px',
    background: 'white',
  },
  logo: {
    display: 'flex',
    padding: '0px 20px'
  },
  hamburger: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '30px', 
    cursor: 'pointer',
    marginRight: '20px', 
  },
  bar: {
    width: '25px',
    height: '3px',
    backgroundColor: '#00B4DB', 
  },
  nav: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',
    padding: '20px',
    borderBottomLeftRadius: '100px',
    position: 'relative',
  },
  navList: {
    display: 'flex',
    listStyle: 'none',
    margin: 0,
    padding: 0,
    flexDirection: 'column',
  },
  navItem: {
    margin: '10px 0',
    color: 'black',
    cursor: 'pointer',
    fontSize: '14px',
  },
  hero: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '120px 10px',
    background: 'linear-gradient(to right, #00B4DB, #0083B0)',
    color: '#ffffff',
  },
  h1: {
    fontSize: '25px',
    margin: '0', 
  },
  textContainer: {
    flex: '1',
    fontSize: '25px',
    lineHeight: '1.5',
    padding: '0 10px',
    textAlign: 'center',
  },
  imageContainer: {
    flex: '1',
    display: 'flex',
    justifyContent: 'center',
    padding: '10px 0',
  },
  heroImage: {
    width: '100%',
    maxWidth: '300px',
    borderRadius: '10px',
  },
  heroParagraph: {
    fontSize: '18px',
    padding: '10px 20px',
    textAlign: 'justify',
  },
  sectionPadding: {
    paddingTop: '80px', 
  },
};

export default MobileApp;
